import { AtlasMessages } from "atlas-ds";
import { useEffect, useState } from "react";
import { fetchAPIBandeaux } from "services/client";
import { Bandeau } from "services/model";
import useSWR from "swr";
import Link from "./link";

export default function Toasts() {
    const bandeaux = useSWR('/bandeaux', fetchAPIBandeaux, {}).data
    const [bandeauxSeen, setBandeauxSeen] = useState<string[]>([])

    useEffect(() => {
        setBandeauxSeen(JSON.parse(localStorage.getItem('bandeauxSeen') ?? "[]"))
    }, [])

    const closeBandeau = (id: string) => {
        setBandeauxSeen([...bandeauxSeen, id])
        localStorage.setItem('bandeauxSeen', JSON.stringify([...bandeauxSeen, id]))
    }

    const isBandeauUnseen = (bandeau: Bandeau): boolean => {
      return !bandeauxSeen.includes(bandeau.id)
    }

    return <AtlasMessages>
        {(bandeaux ?? []).filter(isBandeauUnseen).map(bandeau => <AtlasMessages.Message
            key={ bandeau.id }
            type={ bandeau.type }
            onClose={ () => closeBandeau(bandeau.id) }
            label={ bandeau.titre }
            link={ bandeau.lien ? <Link href={ bandeau.lien }>
                { bandeau.labelLien }
            </Link> : undefined }
        >{ bandeau.contenu }</AtlasMessages.Message>)}
    </AtlasMessages>
}
