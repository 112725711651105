import { AtlasOptions, AtlasValues } from 'atlas-ds';
import { Entreprise } from 'services/model';

interface CompaniesListProps {
    onSelect: (entreprise: any) => void
    entreprises: Entreprise[];
}

export default function CompaniesList(props: CompaniesListProps) {
    return <AtlasOptions
        options={props.entreprises.map(entreprise => ({
            id: entreprise.id,
            onSelect: () => props.onSelect(entreprise),
            content: <AtlasValues label={ entreprise.name }>
                <AtlasValues>
                    <AtlasValues.Value label="SIREN">
                        { entreprise.siren ? entreprise.siren : entreprise.siret?.substring(0, 9) }
                    </AtlasValues.Value>
                    { entreprise.branche && <AtlasValues.Value label="Branche">
                        { entreprise.branche.titre }
                    </AtlasValues.Value> }
                    { entreprise.adresse.trim()
                        ? <AtlasValues.Value full label="Adresse">
                            { entreprise.adresse }
                        </AtlasValues.Value>: <></>
                    }
                </AtlasValues>
            </AtlasValues>
        })) || []}
        loader={<AtlasValues>
            <AtlasValues.Value label="SIREN"> </AtlasValues.Value>
            <AtlasValues.Value label="Branche"> </AtlasValues.Value>
            <AtlasValues.Value full label="Adresse"> </AtlasValues.Value>
        </AtlasValues>}
    />
}
