import { AtlasNav, AtlasUserButton } from 'atlas-ds';
import AtlasMenu from 'atlas-menu';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useEffect, useRef, useState } from 'react';
import { useAPIProfil, useAPIServices } from 'services/client';


export default function Nav() {
    const { data: authSession } = useSession()
    const { data: profil } = useAPIProfil()
    const { data: services } = useAPIServices()
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const menu = useRef(null)
    const buttonRef = useRef(null)

    const login = () => {
        sessionStorage.removeItem('loggingOut')
        signIn('atlas', undefined, { prompt: 'login' })
    }

    useEffect(() => {
        if (!menu.current && services) {
            const logout = () => {
                if (authSession) {
                    console.log("Session logout: " + JSON.stringify(authSession.user))

                    sessionStorage.setItem('loggingOut', 'true')
                    signOut({ redirect: false }).then(() => {
                        sessionStorage.removeItem('loggingOut')
                        window.location.href = `/api/auth/federated-logout?id_token=${authSession.idToken}`
                    })
                }
            }

            menu.current = new AtlasMenu({
                opener: buttonRef.current,
                onLogout: logout,
                top: 26,
                onOpen: () => setMenuIsOpen(true),
                onClose: () => setMenuIsOpen(false),
                services: [
                    {
                        id: "my-fafiec-portail",
                        url: process.env.NEXT_PUBLIC_MENU_SERVICE_FAFIEC
                    },
                    ... services?.includes('sel-gad-adh') ? [{
                        id: "sel-atlas",
                        url: process.env.NEXT_PUBLIC_MENU_SERVICE_ENTREPRISE
                    }] : [],
                    ... services?.includes('selof') ? [{
                        id: "sel-atlas-prestataire",
                        url: process.env.NEXT_PUBLIC_MENU_SERVICE_PRESTATAIRE
                    }] : [],
                    {
                        id: "campus",
                        isCurrentPage: true
                    },
                    {
                        id: "soa",
                        url: process.env.NEXT_PUBLIC_MENU_SERVICE_SOA
                    }
                ],
                parameters: [
                    {
                        id: "compte",
                        url: process.env.NEXT_PUBLIC_MENU_PARAM_COMPTE || null
                    }
                ]
            })
        }
    }, [authSession, services])

    return <AtlasNav
        userButton={<AtlasUserButton
            ref={buttonRef}
            userName={profil ? `${profil?.firstName} ${profil?.lastName}` : undefined}
            onClick={authSession ? undefined : login}
            open={menuIsOpen}
        />}
    />
}
